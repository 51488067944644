import { Outlet } from "@remix-run/react";

export default function Layout() {
  return (
    <div className="flex min-h-screen w-full">
      <div className="relative flex flex-1 items-center justify-center bg-white">
        <div className="relative z-20 w-full max-w-md p-8">
          <Outlet />
        </div>
        <div className="absolute inset-y-0 right-0 z-10 w-1/3 bg-gradient-to-r from-white to-transparent"></div>
      </div>
      <div className="relative hidden w-1/2 overflow-hidden lg:block">
        <div className="absolute inset-0 animate-gradient-shift bg-gradient-to-br from-reliable-navy via-confident-blue to-innovative-lime">
          <div className="absolute inset-0 bg-white/20 backdrop-blur-[50px]"></div>
        </div>
        <div className="absolute inset-0 bg-gradient-to-r from-white via-white/50 to-transparent"></div>
        <div className="absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white to-transparent"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className=" flex h-52 w-52 items-center justify-center ">
            <img src="/dgm_full_logo_navy.png" alt="DGM Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
